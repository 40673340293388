import axios from 'axios';
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FpsView } from 'react-fps';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PanToolIcon from '@mui/icons-material/PanTool';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { get } from 'http';

import CyzyLogoImg from './assets/cyzy.svg';
import ChatWindow from './components/ChatWindow';
import ConfirmationDialog from './components/ConfirmationDialog';
import GuestViewDialog from './components/GuestViewDialog';
import PopupViewDialog from './components/PopupViewDialog';
import ProfileDialog from './components/ProfileDialog';
import RichMenu from './components/RichMenu';
import SettingsDialog, { SettingProps } from './components/SettingsDialog';
import Version from './components/Version';
import AccountContext from './contexts/AccountContext';
import useRichMenu from './hooks/useRichMenu';
import useRoom from './hooks/useRoom';
import MainPage, { UnityConnectionParams } from './MainPage';
import { Bot, Player } from './types/prisma';
import {
  createUserToken,
  getOrCreatePlayer,
  getPlayer,
  getRoomMembers,
  RoomMember,
} from './utils/restApiUtil';

const {
  REACT_APP_API_SERVER_URL,
  REACT_APP_RT_SERVER_URL,
  REACT_APP_RESOURCE_SERVER_URL,
  REACT_APP_RESOURCE_LINK_URL,
  REACT_APP_BOT_POLLING_SPAN,
} = process.env;

const Main = styled('main')`
  background-color: #333333;
  height: 100svh;
`;

const UIRoot = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  pointer-events: none;
  & > * {
    pointer-events: all;
  }
`;

const Toolbar = styled(Box)`
  /* display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  background: #ccebff; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 96px;
`;
const ToolbarInner = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 96px;
  padding: 16px;
  background: #ccebff;
  z-index: 101;
`;

const ToolbarButtonWrap = styled(Box)`
  &:not(:first-of-type) {
    margin-left: 24px;
  }
  button {
    border: 1px solid #e7e7e7;
  }
  .toolbarButtonText {
    margin-top: 4px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
  }
`;

const LobbyWrapper = styled(Backdrop)`
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
`;
const LobbyBg = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
  background-color: lightsteelblue;
  filter: blur(10px);
`;
const LobbyContent = styled(Box)(
  ({ theme }) => `
  width: 90%;
  background: #FFF;
  padding: 32px;
  border-radius: 8px;
  word-break: break-all;
  h4{
    font-size: 16px;
  }
  ${[theme.breakpoints.up('sm')]} {
    width: 480px;
    h4 {
      font-size: 24px;
    }
  }
`
);
const CyzyLogo = styled('img')`
  height: 64px;
  margin-bottom: 16px;
`;
const EnterRoomButton = styled(Button)`
  width: 160px;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 0;
`;
const GuestViewButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  background: #fff !important;
`;
const UIIconButton = styled(IconButton)`
  background: #fff !important;
  /* transition: 0.1s filter ease; */
  &:hover {
    filter: brightness(1.1);
  }

  &.isEmoteActive {
    background: #1876d2 !important;
    color: #ffdc00 !important;
    animation: shake 2s linear infinite;
  }
  @keyframes shake {
    40% {
      rotate: 0deg;
    }
    45% {
      rotate: 20deg;
    }
    55% {
      rotate: -20deg;
    }
    60% {
      rotate: 0deg;
    }
  }
`;
const ToolbarRight = styled(Box)`
  position: absolute;
  display: flex;
  right: 36px;
  height: 96px;
  width: 200px;
  justify-content: flex-end;
`;

type LoadingStatus =
  | 'CheckParams'
  | 'CheckServerRoom'
  | 'CheckServerPlayer'
  | 'CheckServerAvatar'
  | 'Submit'
  | 'LoadScene'
  | 'Complete'
  | 'ErrorParams';

const LOADING_STATUS_TABLE = {
  CheckParams: 'パラメータを確認中...',
  CheckServerRoom: 'ルームを確認中...',
  CheckServerPlayer: 'ユーザー情報を確認中...',
  CheckServerAvatar: 'アバターを確認中...',
  Submit: 'シーンを読み込んでいます...',
  LoadScene: 'シーンを読み込んでいます...',
  Complete: '読み込みが完了しました...',
  ErrorParams: '入室に失敗しました。',
};

// https://stackoverflow.com/questions/60554808/react-useref-with-typescript-and-functional-component
type RefObject = {
  disableUnityInput: () => void;
  enableUnityInput: () => void;
  updateUnityAvatar: (assetName: string) => void;
  enableTpsCamera: () => void;
  disableTpsCamera: () => void;
  enablePostProcessing: () => void;
  disablePostProcessing: () => void;
  enableStreamMovieMute: () => void;
  disableStreamMovieMute: () => void;
  changeVideoVolume: (value: number) => void;
  changeCameraSpeed: (value: number) => void;
  changeMoveSpeed: (value: number) => void;
  setConnection: (value: UnityConnectionParams) => void;
  updateEmote: (roomId: string, state: boolean, name: string) => void;
  enterRoom: (playerId: string) => void;
};

function App() {
  const ref = useRef<RefObject>();
  const chatRef = useRef<HTMLIFrameElement>(null);
  const [chatWindowAnchorEl, setChatWindowAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDialog, setOpenDialog] = useState<string | null>(null);
  const [unityLoaded, setUnityLoaded] = useState(false);
  const [unityEntered, setUnityEntered] = useState(false);
  const [playerCount, setPlayerCount] = useState(0);
  const [isTpsCamera, setIsTpsCamera] = useState(false);
  const [isEmoteActive, setIsEmoteActive] = useState(false);
  const [isStreamMuted, setIsStreamMuted] = useState(true);
  const [currentPlayer, setCurrentPlayer] = useState<Player | null>(null);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingProgression, setLoadingProgression] = useState(0);
  const [playerList, setPlayerList] = useState<RoomMember[]>([]);
  const [unityEmoteNum, setUnityEmoteNum] = useState(0);
  const [search, setSearch] = useSearchParams();
  const [isBotTalk, setIsBotTalk] = useState<string | null>(null); //削除予定
  const [currentBots, setCurrentBots] = useState<{ [id: string]: Bot }>({});
  const [popupUrl, setPopupUrl] = useState<string | undefined>(undefined);
  const StreamVolumeIcon = isStreamMuted ? VolumeOffIcon : VolumeUpIcon;

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const smallDisplay = useMediaQuery(theme.breakpoints.down('md'));

  const roomId = useMemo(() => {
    if (location && location.pathname.includes('/rooms/')) {
      const result = location.pathname.match(/(?<=\/rooms\/)(.+)/);
      return result ? result[0] : '';
    }
    return '';
  }, [location]);

  const isDebbug = useMemo(
    () => (search.get('isDebbug') === 'true' ? true : false),
    [search]
  );
  useEffect(() => {
    if (!roomId && process.env.REACT_APP_DEFAULT_ROOM_ID) {
      const id = process.env.REACT_APP_DEFAULT_ROOM_ID;
      navigate({
        pathname: `/rooms/${id}`,
      });
    }
  }, [roomId, navigate]);

  const getPlayerList = useCallback(async () => {
    if (roomId) {
      const items = await getRoomMembers(roomId);
      setPlayerList(items);
      const emoteNum: number = items.filter((v) => v.status > 0).length;
      setUnityEmoteNum(emoteNum);
    }
  }, [roomId]);

  useEffect(() => {
    getPlayerList();
  }, [getPlayerList]);

  const richMenu = useRichMenu(roomId);
  const room = useRoom(roomId);

  const handleMenuClick = (e: MouseEvent<HTMLButtonElement>) => {
    // メニューボタンクリック
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleToggleTPSClick = () => {
    // 三人称視点ボタンクリック
    if (!ref.current) {
      return;
    }
    isTpsCamera
      ? ref.current.disableTpsCamera()
      : ref.current.enableTpsCamera();
    setIsTpsCamera(!isTpsCamera);
  };
  const handleStreamMutedClick = () => {
    //ストリーミングミュートボタンクリック
    if (!ref.current || !room?.videoUrl) {
      return;
    }
    isStreamMuted
      ? ref.current.enableStreamMovieMute()
      : ref.current.disableStreamMovieMute();
    setIsStreamMuted(!isStreamMuted);
  };
  const handleRaiseHandClick = () => {
    // 挙手ボタンクリック
    if (!ref.current) {
      return;
    }
    setIsEmoteActive(!isEmoteActive);
    ref.current.updateEmote(roomId, !isEmoteActive, 'raiseHand');

    !isEmoteActive
      ? setUnityEmoteNum(unityEmoteNum + 1)
      : setUnityEmoteNum(unityEmoteNum - 1);
  };
  const handleChatClick = (event: MouseEvent<HTMLButtonElement>) => {
    setChatWindowAnchorEl((c) => (c ? null : event.currentTarget));
  };

  const handleGuestViewClick = async (event: MouseEvent<HTMLButtonElement>) => {
    setOpenDialog('guestView');
    await getPlayerList();
  };

  const handleMainPageClick = () => {
    console.log('handleMainPageClick');
    if (ref.current) {
      ref.current.enableUnityInput();
    }
  };

  const handleUIClick = () => {
    console.log('handleUIClick');
    if (ref.current) {
      ref.current.disableUnityInput();
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleUnityLoaded = (isLoaded: boolean, progression: number) => {
    setLoadingProgression(progression);
    if (isLoaded) {
      setUnityLoaded(true);
    }
  };

  const handleUnityEnteringRoom = (status: string) => {
    const message = LOADING_STATUS_TABLE[status as LoadingStatus];
    if (!message) {
      console.error('unknow status', status);
      return;
    }

    if ('ErrorParams' === status) {
      alert('入室に失敗しました。');
    } else {
      setLoadingStatus(message);
      if ('Complete' === status) {
        setUnityEntered(true);
      }
    }
  };
  const handleUnityRedirect = async (data: string) => {
    if (!data) {
      console.log('unknown url', data);
    }

    const url = new URL(data);
    if (url.searchParams.has('popupView')) {
      // disable chat window
      setChatWindowAnchorEl(null);
      // popup in the current page
      setPopupUrl(url.toString());
      setOpenDialog('popup');
    } else {
      const userToken: string | null | undefined = await createUserToken(
        currentPlayer?.id
      );
      console.log(userToken);
      url.searchParams.append('cyzyUserToken', userToken || '');
      // jump to others
      // window.location.href = url.toString();
      window.open(url.toString(), '_blank');
    }
  };

  const handleEnterRoom = async () => {
    console.log(room);
    if (!ref.current) {
      return;
    }

    const player = await getOrCreatePlayer();

    setCurrentPlayer(player);

    if (player) {
      ref.current.setConnection({
        url_api: REACT_APP_API_SERVER_URL,
        url_ws: REACT_APP_RT_SERVER_URL,
        url_resource: REACT_APP_RESOURCE_SERVER_URL,
        url_texture_source: REACT_APP_RESOURCE_LINK_URL,
        bot_polling_span: REACT_APP_BOT_POLLING_SPAN,
      });
      ref.current.enterRoom(player.id);
    }
  };

  const handleUnityUpdateEmote = (data: string) => {
    const obj = JSON.parse(data);
    if (obj.Item1 !== undefined && obj.Item2 !== undefined) {
      if (obj.Item1 && playerList) {
        setPlayerList((prevList) => {
          const updatedList = prevList.map((item) => {
            if (item.id === obj.Item1) {
              return { ...item, status: obj.Item2 };
            }
            return item;
          });
          return updatedList;
        });
      }
      if (obj.Item2 === 0) {
        if (unityEmoteNum !== 0) {
          setUnityEmoteNum(unityEmoteNum - 1);
        }
      } else {
        setUnityEmoteNum(unityEmoteNum + 1);
      }
    }
  };

  const handleUnityUpdatePlayerNum = (count: number) => {
    setPlayerCount(count + 1 /*自分*/);
  };

  const handleUnityBotEnter = (data: string) => {
    const parsedData = JSON.parse(data) as Bot;

    if (parsedData?.id) {
      setCurrentBots((prevState) => {
        const updatedData = { ...prevState[parsedData.id], ...parsedData };
        return { ...prevState, [parsedData.id]: updatedData };
      });
    }
  };

  const handleUnityBotExit = (data: string) => {
    const parsedData = JSON.parse(data) as Bot;
    if (parsedData?.id) {
      setCurrentBots((prevState) => {
        const newState = { ...prevState };
        delete newState[parsedData.id];
        return newState;
      });
    }
  };

  const handleUnitySocketStatus = (data: string) => {
    const obj = JSON.parse(data);
    if (obj.Item1 !== undefined && obj.Item2 !== undefined) {
      console.log(obj);
      switch (obj.Item1) {
        case 'onError':
          alert('サーバとの通信中にエラーが発生しました。ページを再読込します');
          window.location.reload();
          break;
        case 'onClose':
          alert('サーバとの通信が切断されました。ページを再読込します');
          window.location.reload();
          break;
        default:
          break;
      }
    }
  };

  const handleExitRoom = () => {
    window.location.assign('https://cyzyspace.io');
  };
  const handleUpdatePlayerAvatar = async (avatarName: string) => {
    // context を更新
    await accountContext.refreshPlayer();
    // unity に通知
    if (avatarName) {
      ref.current?.updateUnityAvatar(avatarName);
    }
    setOpenDialog(null);
  };

  const handleUpdateSettings = (props: SettingProps) => {
    // ここに送信する処理を記載
    if (props.videoVolume != null) {
      ref.current?.changeVideoVolume(props.videoVolume / 100.0);
    }
    if (props.moveSpeed != null) {
      ref.current?.changeMoveSpeed(props.moveSpeed);
    }
    if (props.cameraSpeed != null) {
      ref.current?.changeCameraSpeed(props.cameraSpeed / 100.0);
    }
    if (props.enablePostProcess != null) {
      if (props.enablePostProcess) {
        ref.current?.enablePostProcessing();
      } else {
        ref.current?.disablePostProcessing();
      }
    }
    setOpenDialog(null);
  };

  const handleMessageSubmit = (message: string) => {
    const processed = message.trim();
    if (processed) {
      chatRef.current?.contentWindow?.postMessage(
        {
          type: 'cyzy-chat-message',
          text: processed,
          botName: isBotTalk, //削除予定
          botDatas: currentBots,
        },
        process.env.REACT_APP_CHAT_SERVICE_URL as string
      );
    }
  };

  const refreshPlayer = async () => {
    const player = await getPlayer(currentPlayer?.id);
    setCurrentPlayer(player);
  };

  const accountContext = {
    player: currentPlayer,
    room,
    refreshPlayer,
  };

  return (
    <AccountContext.Provider value={accountContext}>
      {isDebbug && <FpsView />}
      <Box sx={{ display: 'flex', height: '100%' }}>
        <CssBaseline />
        <LobbyWrapper
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={!unityEntered}
        >
          <LobbyBg
            sx={{
              background: `url(${room?.scene_thumbnailUrl}) center center /cover no-repeat`,
            }}
          ></LobbyBg>
          <LobbyContent
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
          >
            <CyzyLogo src={CyzyLogoImg} alt="CYZY SPACE" />
            {!roomId && (
              <Typography variant="h6">
                エラー：入室先が指定されていません
              </Typography>
            )}
            {roomId && !loadingStatus && (
              <Stack spacing={3}>
                <Box>
                  <Typography variant="caption" sx={{ color: '#777' }}>
                    ルーム名
                  </Typography>
                  <Typography variant="h4">{room?.name}</Typography>
                </Box>
                {unityLoaded && (
                  <Box>
                    <EnterRoomButton
                      onClick={handleEnterRoom}
                      variant="contained"
                      size="large"
                    >
                      <LoginIcon sx={{ marginRight: '8px' }} />
                      入室する
                    </EnterRoomButton>
                    <Link
                      href="https://docs.cyzyspace.io/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                      display="block"
                      variant="caption"
                      sx={{ color: '#777', mt: 2 }}
                    >
                      利用規約
                    </Link>
                  </Box>
                )}
                {!unityLoaded && (
                  <Box>
                    <Typography variant="caption">{`ダウンロードしています...`}</Typography>
                    <LinearProgress
                      variant="determinate"
                      value={loadingProgression * 100}
                    />
                  </Box>
                )}
              </Stack>
            )}
            {loadingStatus && (
              <Stack sx={{ textAlign: 'center' }}>
                <Box>
                  <CircularProgress />
                </Box>
                <Box>
                  <Typography variant="body1">入室中です</Typography>
                  <Typography variant="caption">{loadingStatus}</Typography>
                </Box>
              </Stack>
            )}
          </LobbyContent>
        </LobbyWrapper>

        <Main onClick={handleMainPageClick} onTouchStart={handleMainPageClick}>
          <MainPage
            ref={ref}
            onLoaded={handleUnityLoaded}
            onUnityEnteringRoom={handleUnityEnteringRoom}
            onUnityUpdatePlayerNum={handleUnityUpdatePlayerNum}
            onUnityUpdateEmote={handleUnityUpdateEmote}
            onUnitySocketStatus={handleUnitySocketStatus}
            onUnityRedirect={handleUnityRedirect}
            onUnityBotEnter={handleUnityBotEnter}
            onUnityBotExit={handleUnityBotExit}
          />
        </Main>
        {roomId && (
          <UIRoot onClick={handleUIClick} id="ui-root" hidden={!unityEntered}>
            <GuestViewButton size="large" onClick={handleGuestViewClick}>
              <PersonIcon />
              <span>{playerCount}</span>
              <PanToolIcon
                sx={{
                  marginLeft: '8px',
                  marginRight: '4px',
                  fontSize: '18px',
                  color: unityEmoteNum > 0 ? '#e6860a' : 'inherit',
                }}
              />
              <span>{unityEmoteNum}</span>
            </GuestViewButton>
            <UIIconButton
              aria-describedby={id}
              size="large"
              color="primary"
              aria-label="メニュー"
              sx={{
                position: 'absolute',
                top: '16px',
                left: '16px',
                display: smallDisplay ? 'flex' : 'none',
              }}
              onClick={handleMenuClick}
            >
              <MoreVertIcon />
            </UIIconButton>
            <Popover
              // メニューのリスト
              open={open}
              onClose={handleMenuClose}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: smallDisplay ? 'bottom' : 'top',
                horizontal: smallDisplay ? 'left' : 'right',
              }}
              transformOrigin={{
                vertical: smallDisplay ? 'top' : 'bottom',
                horizontal: smallDisplay ? 'left' : 'right',
              }}
              id={id}
            >
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setOpenDialog('editProfile');
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="名前/アバター変更" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setOpenDialog('settings');
                      setAnchorEl(null);
                    }}
                  >
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="設定変更" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      window.open(
                        'https://docs.cyzyspace.io/terms-of-use',
                        '_blank'
                      );
                    }}
                  >
                    <ListItemIcon>
                      <DescriptionOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="利用規約" />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  disablePadding
                  sx={{ display: smallDisplay ? 'block' : 'none' }}
                >
                  <ListItemButton onClick={() => setOpenDialog('exit')}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="退出する" />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <Version />
                </ListItem>
              </List>
            </Popover>
            <Toolbar>
              {richMenu && <RichMenu data={richMenu} />}
              <ToolbarInner>
                <ToolbarButtonWrap
                  sx={{
                    display:
                      !room?.videoUrl || room?.videoUrl.length === 0
                        ? 'none'
                        : 'block',
                  }}
                >
                  <UIIconButton
                    size="large"
                    color="primary"
                    aria-label="音声切り替え"
                    onClick={handleStreamMutedClick}
                  >
                    <StreamVolumeIcon />
                  </UIIconButton>
                  <Typography variant="body2" className="toolbarButtonText">
                    ミュート
                  </Typography>
                </ToolbarButtonWrap>
                <ToolbarButtonWrap>
                  <UIIconButton
                    size="large"
                    color="primary"
                    aria-label="視点切り替え"
                    onClick={handleToggleTPSClick}
                  >
                    <VisibilityIcon />
                  </UIIconButton>
                  <Typography variant="body2" className="toolbarButtonText">
                    視点変更
                  </Typography>
                </ToolbarButtonWrap>
                <ToolbarButtonWrap>
                  <UIIconButton
                    size="large"
                    color="primary"
                    aria-label="挙手"
                    onClick={handleRaiseHandClick}
                    className={isEmoteActive ? 'isEmoteActive' : ''}
                  >
                    <PanToolIcon sx={{ paddingRight: '2px' }} />
                  </UIIconButton>
                  <Typography variant="body2" className="toolbarButtonText">
                    挙手
                  </Typography>
                </ToolbarButtonWrap>
                <ToolbarButtonWrap>
                  <UIIconButton
                    size="large"
                    color="primary"
                    aria-label="チャット"
                    onClick={handleChatClick}
                  >
                    <ChatBubbleIcon />
                  </UIIconButton>
                  <Typography variant="body2" className="toolbarButtonText">
                    チャット
                  </Typography>
                </ToolbarButtonWrap>
                <ToolbarRight
                  sx={{
                    display: smallDisplay ? 'none' : 'flex',
                  }}
                >
                  <ToolbarButtonWrap>
                    <UIIconButton
                      aria-describedby={id}
                      size="large"
                      color="error"
                      aria-label="退出する"
                      onClick={() => setOpenDialog('exit')}
                      sx={{
                        color: '#FFF',
                        border: '#F5325C !important',
                        background: '#F5325C !important',
                      }}
                    >
                      <LogoutIcon />
                    </UIIconButton>
                    <Typography variant="body2" className="toolbarButtonText">
                      退出する
                    </Typography>
                  </ToolbarButtonWrap>
                  <ToolbarButtonWrap>
                    <UIIconButton
                      aria-describedby={id}
                      size="large"
                      color="primary"
                      aria-label="メニュー"
                      onClick={handleMenuClick}
                    >
                      <MoreVertIcon />
                    </UIIconButton>
                    <Typography variant="body2" className="toolbarButtonText">
                      メニュー
                    </Typography>
                  </ToolbarButtonWrap>
                </ToolbarRight>
              </ToolbarInner>
            </Toolbar>
            <PopupViewDialog
              open={openDialog === 'popup'}
              onCancel={handleDialogClose}
              url={popupUrl}
            ></PopupViewDialog>
            <GuestViewDialog
              openDialog={openDialog}
              handleDialogClose={handleDialogClose}
              roomId={roomId}
              playerCount={playerCount}
              playerList={playerList}
            />
            <ProfileDialog
              onCancel={handleDialogClose}
              onSubmit={handleUpdatePlayerAvatar}
              open={openDialog === 'editProfile'}
              id="editProfile"
            />
            <SettingsDialog
              open={openDialog === 'settings'}
              onCancel={handleDialogClose}
              onSubmit={handleUpdateSettings}
            />
            <ConfirmationDialog
              open={openDialog === 'exit'}
              onCancel={handleDialogClose}
              onSubmit={handleExitRoom}
              message="退出します。よろしいですか？"
            />
            <ChatWindow
              open={Boolean(chatWindowAnchorEl)}
              id="chat-window"
              anchorEl={null}
              roomId={roomId}
              userName={currentPlayer?.name || ''}
              ref={chatRef}
              onMessageSubmit={handleMessageSubmit}
            />
          </UIRoot>
        )}
      </Box>
    </AccountContext.Provider>
  );
}

export default App;
