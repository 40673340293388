import { forwardRef, KeyboardEvent, useState } from 'react';

import { Box, Fade, Popper, styled, TextField } from '@mui/material';

const ChatWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 320px;
  height: calc(100svh - 345px);
  ${[theme.breakpoints.up('md')]} {
    height: calc(100svh - 200px);
  }
  margin-bottom: 8px;
  border-radius: 8px;
  background: #93bae0;
  border: 2px solid #1876d2;
`
);
const ChatIFrame = styled('iframe')`
  flex-grow: 1;
  border: 0;
  width: 100%;
  border-radius: 6px 6px 0 0;
  background-color: #dce3ea;
`;

const StyledTextField = styled(TextField)`
  border: none;
  padding: 8px;
  .MuiInputBase-root {
    padding: 8px 12px;
    background-color: #fff;
  }
  textarea {
    font-size: 14px;
    line-height: 18px;
  }
`;

export type ChatWindowProps = {
  open: boolean;
  id: string;
  anchorEl: HTMLElement | null;
  userName: string;
  roomId: string;
  onMessageSubmit: (message: string) => void;
};

const ChatWindow = forwardRef<HTMLIFrameElement, ChatWindowProps>(
  ({ open, id, anchorEl, roomId, userName, onMessageSubmit }, ref) => {
    const [message, setMessage] = useState('');
    const [sent, setSent] = useState(false);

    const handleMessageChange = (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      if (sent) {
        setSent(false);
        setMessage('');
      } else {
        setMessage(event.currentTarget.value);
      }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (
        event.code === 'Enter' &&
        event.shiftKey === false &&
        !event.nativeEvent
          .isComposing /*変換中のEnterを除外するための暫定処置。ただしSafariでは効かない */ &&
        message.length > 0
      ) {
        onMessageSubmit && onMessageSubmit(message);
        setSent(true); // ここで setMessage を呼んでも onChange があとから来るのでフラグを立てるだけにする
      }
    };

    return (
      <>
        <Popper
          id={id}
          open={open}
          anchorEl={null}
          transition
          placement="top-start"
          sx={{
            zIndex: '101',
            bottom: { xs: '270px', sm: '270px', md: '120px' },
            right: '16px',
            left: 'auto !important',
            top: 'auto !important',
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <ChatWrapper>
                <ChatIFrame
                  ref={ref}
                  title="chat-component"
                  src={`${process.env.REACT_APP_CHAT_SERVICE_URL}/room?roomId=${roomId}&userName=${userName}&hideInput=1`}
                  onClick={() => {
                    console.log('iframe onClick');
                  }}
                ></ChatIFrame>
                <StyledTextField
                  size="small"
                  fullWidth
                  multiline
                  maxRows={5}
                  placeholder="メッセージを入力"
                  value={message}
                  onChange={handleMessageChange}
                  onKeyDown={handleKeyDown}
                />
              </ChatWrapper>
            </Fade>
          )}
        </Popper>
      </>
    );
  }
);

export default ChatWindow;
